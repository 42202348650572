<template>
    <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="slide in slides" :key="slide.id">
            <video class="video" v-if="slide.video"  playsinline :src="getImagePath(slide.video)" autoplay loop muted></video>
            <image-component v-else class="slider-img" :path="slide.image" :style="{opacity: slide.opacity || 1}"/>
            <div class="slide-caption">
                <h2  v-if="slide.main_title">{{slide.main_title}}</h2>
                <p v-if="slide.second_title">{{slide.second_title}}</p>
                <a v-if="slide.btn_title" class="btn btn-lg" v-bind:style="{'background-color': slide.btn_color}" :href="slide.link">
                    {{slide.btn_title}}</a>
            </div>
        </swiper-slide>

        <div class="swiper-button-prev nav-btn" slot="button-prev"></div>
        <div class="swiper-button-next nav-btn" slot="button-next"></div>
    </swiper>
</template>


<script>
    import Vue from 'vue';
    //import {Swiper as SwiperClass, Pagination, Navigation, Mousewheel, Autoplay} from 'swiper/swiper.esm.js';
    import ImageComponent from "../../../components/base-components/ImageComponent";
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css';

    //SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
    //Vue.use(getAwesomeSwiper(SwiperClass));
    //const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass);

    export default {
        name: "SliderComponent",
        data() {
            return {
                swiperOption: {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    effect: 'coverflip',
                    speed: 1000,
                    lazyLoading: true,
                    autoplay: {
                      delay: 12000,
                    },
                    slidesPerView: 1,
                    EffectFade: true,
                    autoplayStopOnLast: false,
                    mousewheelControl: true,
                    autoHeight: true,
                    //sliderHeight: 300,
                }
            }
        },
        components: {
          ImageComponent,
            Swiper,
            SwiperSlide
        },
        props: {
            slides: Array
        },
    }
</script>

<style lang="scss">
    //@import '~swiper/swiper-bundle.css';
    @import "src/assets/css/colors";
    @import "src/assets/css/mixins";
    .slide-caption,
    .swiper-slide,
    .swiper {
      width: 100%
    }


    .nav-btn {
        background-color: rgba($button-primary-rgb, 0.3);
        width: 52px;
        height: 52px;
        color: $text-secondary;
        position: absolute;
        top: 50%;
        z-index: 1;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after{
        color: $secondary-background;
        font-size: 1.5rem!important;
        font-weight: 700;
        .swiper-button-disabled{
            color: $text-tertiary-middle;
        }
    }
    .swiper-button-next{
        right: 0;
    }
    .swiper-button-prev{
        left: 0;
    }

    .slider-img {
      position: relative;
      width: 100%;
      opacity: 0.9;
    }

    .video {
        position: relative;
        width: 100%;
    }

    .swiper {
        height: 100%;
        width: 100%;
        margin-top: 70px;
        @include media-max(md){
          margin-top: 80px;
        }

        .swiper-slide {
            display: flex;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: bold;
            font-size: 1rem;
            background-color: $text-secondary;
        }
    }

    .slide-caption {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateY(0px);
        opacity: 1;
        text-align: center;
        z-index: 1;
        color: $text-tertiary-light;
        text-shadow: 4px 2px 8px rgba($button-primary-rgb, .71);
        & > * {
            max-width: 800px;
        }

        h2 {
            font-size: 4.25rem;
            letter-spacing: 3px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 3rem;
            @include media-min(xxl) {
                font-size: 3.5rem;
                margin-bottom: 2.5rem;
            }
            @include media-max(sm) {
                font-size: 3rem;
                margin-bottom: 1rem;
            }
            @include media-max(xs) {
                font-size: 2rem;
                margin-bottom: .5rem;
            }
        }

        p {
            text-transform: capitalize;
            font-weight: 300;
            font-size: 1.625rem;
            line-height: 1.8;
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
            color: #003876;
            @include media-max(xs) {
                font-size: 1rem;
            }
        }
        .btn {
            font-weight: 700;
            color: var(--settings-slider-button-text-color);
            background-color: var(--settings-slider-button-background);
            @include media-max(xs) {
                padding: .3rem .6rem;
                font-size: 1rem;
            }
        }
    }

</style>
