<template>
    <img
            :src="imageSrc"
            v-bind="$attrs"
    />
</template>

<script>
  import noimage from "../../assets/img/no-image.jpg"

  export default {
    name: "ImageComponent",
    props: {
      path: {
        type: [Object, String],
      }
    },
    computed: {
      imageSrc(){
        if (!this.path) return noimage;

        if (typeof this.path === "string"){
          return this.getImagePath(this.path)
        }
        if (typeof this.path === "object"){
          const quality = Math.max(0, ...Object.keys(this.path).map(Number).filter(Number.isInteger))
          return this.getImagePath(this.path[quality])
        }
        return noimage
      }
    }
  }
</script>

<style scoped>

</style>